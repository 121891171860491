import React from 'react';
import s from './SignInForm.module.scss';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/app/BLL/createAction';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PasswordField } from 'src/app/ui/formFields/PasswordField';
import { StringField } from 'src/app/ui/formFields/StringField';
import { ButtonSquared } from 'src/shared/ui/buttons/ButtonSquared';
import { actionsAuth } from 'src/pages/auth/_BLL/slice';
import { actionsMetaDictionaries } from 'src/pages/app/_BLL/metaDictionaries/slice';

export type SignInBody = {
	username: string
	password: string
}

export const SignInForm = () => {

	// * Selectors
	const authStatus = useAppSelector(state => state.auth.status);
	const dictionariesStatus = useAppSelector(state => state.metaDictionaries.status);

	const isLoading = authStatus === 'LOADING' || dictionariesStatus === 'LOADING';

	// * Actions
	const dispatch = useAppDispatch();
	const {signIn} = actionsAuth;
	const {getDictionaries} = actionsMetaDictionaries;

	const schema = Yup.object().shape({
		username: Yup.string().required(),
		password: Yup.string().required(),
	});

	const formMethods = useForm({
		defaultValues: {
			username: '',
			password: '',
		},
		resolver: yupResolver(schema),
		mode: 'onChange'
	});

	const {handleSubmit} = formMethods;

	const onSubmit = (values: SignInBody) => {
		dispatch(signIn(values))
			.unwrap()
			.then(({token}) => dispatch(getDictionaries({token})));
	};

	// * Component
	return (
		<form
			className={s.form}
			onSubmit={handleSubmit(onSubmit)}
		>
			<FormProvider {...formMethods}>
				<StringField
					label="User name"
					name="username"
				/>

				<PasswordField
					label="Password"
					name="password"
				/>
			</FormProvider>

			<ButtonSquared
				type="submit"
				isLoading={isLoading}
			>
				Sign in
			</ButtonSquared>
		</form>);
};
