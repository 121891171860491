import React, { useState } from 'react';
import s from './CompanyCard.module.scss';
import { DivProps } from 'src/shared/types/props';
import cn from 'classnames';
import companySvg from 'src/shared/assets/svg/illustrations/undraw_survey_05s5.svg';
import { SingleInfo } from 'src/entities/SingleInfo';
import { ButtonsWrapper } from 'src/shared/ui/wrappers/ButtonsWrapper';
import { RouterPath } from 'src/pages/app/router/AppRouter';
import { useNavigate } from 'react-router-dom';
import { RatingType } from 'src/pages/MainPage/_BLL/types';
import { Modal } from 'src/shared/ui/Modal/Modal';
import { CertificatesMC } from 'src/widgets/CertificatesMC';
import { ButtonSquared } from 'src/shared/ui/buttons/ButtonSquared';

interface Props extends DivProps {
	companyId: number;
	companyName: string;
	score: number;
	absoluteRating: string;
	relativeRating: string;
	ratingType: RatingType;
}

export const CompanyCard: React.FC<Props> = props => {
	const {
		companyId,
		companyName,
		score,
		absoluteRating,
		relativeRating,
		ratingType,
		className,
		...divProps
	} = props;

	// * Router
	const navigate = useNavigate();

	const openEditCompany = () => navigate(RouterPath.companyPage + `/${ratingType}/${companyId}`);

	const [certificatesModal, setCertificatesModal] = useState(false);
	const openCertificatesModal = () => setCertificatesModal(true);
	const closeCertificatesModal = () => setCertificatesModal(false);

	// * Render
	return (<>
		<Modal
			isOpen={certificatesModal}
			onClose={closeCertificatesModal}
		>
			<CertificatesMC
				companyId={companyId}
				companyName={companyName}
				score={score}
				absoluteRating={absoluteRating}
				relativeRating={relativeRating}
			/>
		</Modal>

		<div {...divProps} className={cn(s.container, className)}>
			<div>
				<div className={s.info}>
					<SingleInfo
						caption="DEI Id"
						info={companyId}
					/>

					<SingleInfo
						caption="Name"
						info={companyName}
					/>
				</div>

				<ButtonsWrapper>
					<ButtonSquared
						onClick={openEditCompany}
					>
						Edit company
					</ButtonSquared>

					<ButtonSquared
						onClick={openCertificatesModal}
					>
						Certificates
					</ButtonSquared>
				</ButtonsWrapper>
			</div>

			<img className={s.img} src={companySvg} alt="company image"/>
		</div>
	</>);
};
