import React, { memo } from 'react';
import s from './DropDownField.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem, Select } from '@mui/material';
import { GeneratedField, StatusType } from 'src/pages/company/_BLL/types';
import { ValueDictionaryToolTip } from 'src/pages/company/ui/dataPointGenerator/fields/_field-elements/ValueDictionaryTooltip/ValueDictionaryTooltip';

export const DropDownField: React.FC<GeneratedField> = memo(props => {
	const { namePrefix, propertyName, internalName, validate, fieldDictionary } = props;

	// * Form
	const { setValue, control } = useFormContext();

	const name = `${namePrefix}${propertyName}.${internalName}`;

	const handleChange = (fieldName: string, value: unknown) => {
		if (value === null) {
			setValue(`${name}.status`, StatusType.EMPTY);
		} else {
			setValue(`${name}.status`, StatusType.FILLED);
		}

		setValue(fieldName, value, { shouldValidate: true });
	};

	return (
		<Controller
			name={`${name}.value`}
			control={control}
			rules={{ validate: validate }}
			render={({ field, fieldState }) => (
				<div>
					<div className={s.fieldWrapper}>
						<Select
							{...field}
							className={s.select}
							onChange={e => handleChange(field.name, e.target.value)}
							size="small"
						>
							{/*@ts-ignore*/}
							<MenuItem value={null}>Unknown</MenuItem>
							{fieldDictionary &&
								fieldDictionary.map((dictionary: any) => (
									<MenuItem
										key={dictionary.id}
										value={dictionary.id.toString()}
									>
										{dictionary.name}
									</MenuItem>
								))}
						</Select>

						{fieldDictionary && (
							<ValueDictionaryToolTip
								title={field.value}
								fieldDictionary={fieldDictionary}
							/>
						)}
					</div>

					{fieldState.error && <span className={s.error}>{fieldState.error.message}</span>}
				</div>
			)}
		/>
	);
});
