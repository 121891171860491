import React from 'react';
import s from './DropDownManyField.module.scss';
import { uniqueArray } from 'src/helpers/dataHelpers';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { MenuItem, Select } from '@mui/material';
import { GeneratedField, StatusType } from 'src/pages/company/_BLL/types';
import { MultiValuesTooltip } from 'src/pages/company/ui/dataPointGenerator/fields/_field-elements/MultiValuesTooltip/MultiValuesTooltip';
import { DictionaryItem } from 'src/pages/app/_BLL/metaDictionaries/types';

interface Props extends GeneratedField {}

export const DropDownManyField: React.FC<Props> = props => {
	const { namePrefix, propertyName, internalName, validate, fieldDictionary } = props;

	// * Form
	const { setValue, control } = useFormContext();

	const sectorValues: string[] | null = useWatch({
		name: `${namePrefix}${propertyName}.sector.values`,
	});

	const industryValues: string[] | null = useWatch({
		name: `${namePrefix}${propertyName}.industry.values`,
	});

	const name = `${namePrefix}${propertyName}.${internalName}`;

	const handleValue = (value: string[]) => {
		if (value === null) {
			return [];
		}
		return value;
	};

	const handleChange = (fieldName: string, values: Array<string | string[]>) => {
		const toUniqueFlatArray = (values: Array<string | string[]>) => {
			return uniqueArray(values.flat());
		};

		if (values.length === 0) {
			setValue(`${name}.status`, StatusType.EMPTY);
			setValue(fieldName, null);

			name === sectorName && clearIndustries();
		} else {
			setValue(`${name}.status`, StatusType.FILLED);
			setValue(fieldName, toUniqueFlatArray(values));

			name === sectorName && filterIndustries(values.flat());
		}
	};

	// * IndustryAndSector only ...
	const industryName = 'properties.industryAndSector.industry';
	const sectorName = 'properties.industryAndSector.sector';

	const disableIndustry = name === industryName && sectorValues === null;

	const clearIndustries = () => {
		setValue(`${industryName}.values`, null);
	};

	// The function removes existing sectors when the industry remove.
	// Example: If sector Agriculture is removed the industries of the agriculture also removed.
	const filterIndustries = (sectorValues: string[]) => {
		if (sectorValues && industryValues) {
			let filteredIndustryValues: string[] = [];

			for (const value of sectorValues) {
				filteredIndustryValues = [
					...filteredIndustryValues,
					...industryValues.filter(industryValue => industryValue.substring(0, 2) === value.substring(0, 2) && industryValue.length === value.length),
				];
			}

			if (industryValues.length === 0) {
				setValue(`${industryName}.values`, null);
			} else {
				setValue(`${industryName}.values`, uniqueArray(filteredIndustryValues));
			}
		}
	};

	const dictionaryFilter = (dictionary: DictionaryItem[]): DictionaryItem[] => {
		if (name === industryName) {
			if (sectorValues) {
				let filteredDictionary: DictionaryItem[] = [];

				for (const value of sectorValues) {
					filteredDictionary = [...filteredDictionary, ...dictionary.filter(item => String(item.id).slice(0, -3) + '000' === value)];
				}

				return filteredDictionary;
			} else {
				return [];
			}
		} else {
			return dictionary;
		}
	};

	// * Component
	return (
		<Controller
			name={`${name}.values`}
			control={control}
			rules={{ validate: validate }}
			render={({ field, fieldState }) => (
				<div>
					<div className={s.fieldWrapper}>
						<Select
							className={s.select}
							value={handleValue(field.value)}
							disabled={disableIndustry}
							multiple
							onChange={event => handleChange(field.name, event.target.value as string[])}
							size="small"
						>
							{fieldDictionary && <MenuItem value={dictionaryFilter(fieldDictionary).map(dictionary => dictionary.id.toString())}>Select All</MenuItem>}

							{fieldDictionary &&
								dictionaryFilter(fieldDictionary).map(dictionary => (
									<MenuItem
										key={dictionary.id}
										value={dictionary.id.toString()}
									>
										{dictionary.name}
									</MenuItem>
								))}
						</Select>

						{fieldDictionary && (
							<MultiValuesTooltip
								title={field.value}
								fieldDictionary={fieldDictionary}
							/>
						)}
					</div>

					{fieldState.error && <span className={s.error}>{fieldState.error.message}</span>}
				</div>
			)}
		/>
	);
};
